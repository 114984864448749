@import '../../styles/variables';

.event {
  display: grid;

  ul {
    margin-top: 0;
  }

  &__info {
    border-top: 1px solid black;
    position: relative;

    &:nth-child(n) {
      width: 100%;
    }
  }

  &__link-wrapper {
    padding-left: 20px;
  }

  &__type {
    display: block;
    font-weight: bold;
    font-size: 14.5px;
  }

  &__name {
    margin: 0;
    font-weight: bold;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-style: italic;
  }

  &__subtitle {
    margin: 0;
    font-weight: normal;
  }

  &__date {
    margin: 0 0 0 5px;
    text-transform: capitalize;
    font-weight: normal;
  }

  &__description {
    margin: 0 0 18px;
    font-weight: normal;
    white-space: pre-line;
  }

  &__link {
    list-style: none;
    position: relative;

    &:before {
      content: '●';
      margin-right: 5px;
    }

    a {
      font-size: 14.5px;
      line-height: inherit;
      font-weight: normal;
      white-space: pre-line;
      word-break: break-word;
      color: $black;
      text-decoration: none;

      &:hover {
        color: $black;
        text-decoration: none;
      }
    }
  }

  &__btn-container {
    /*margin-bottom: 18px;*/
    button {
      font-family: inherit;
      color: black;
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
    }

    .read-less {
      color: black;
      vertical-align: sub;
      &::before {
        margin-right: 5px;
        display: inline-block;
        content: '▼';
      }
    }

    .read-more {
      color: black;
      vertical-align: sub;
      &::before {
        margin-right: 5px;
        display: inline-block;
        content: '▼';
        transform: rotate(270deg);
      }
    }
  }

  &__sub-description {
    margin: 0 0 20px;
    font-weight: normal;
    white-space: pre-line;
  }
}

@media (min-width: $xs) {
  .event {
    grid-template-columns: 1fr;
    margin-top: 0px;
    border-bottom: none;

    &__type {
      margin-bottom: 20px;
    }

    .read-less,
    .read-more {
      padding: 0px 0px 20px 0px;
      display: inline-block;
    }

    &__info {
      padding-top: 20px;
    }

    &__name {
      font-size: 14.5px;
    }

    &__title {
      font-size: 14.5px;
    }

    &__subtitle {
      font-size: 14.5px;
    }

    &__date {
      text-transform: capitalize;
      font-size: 14.5px;
    }

    &__description {
      font-size: 14.5px;
    }

    &__sub-description {
      font-size: 14.5px;
    }

    &__title-container {
      padding: 0px 0 0 20px;
    }

    &__subtitle-container {
      padding: 0 0 16px 20px;
    }

    &__btn-container {
      padding: 0px 0px 0px 0px;

      button {
        font-size: 14.5px;
      }
    }

    &__content {
      margin-bottom: 0;
      padding: 0;
    }

    &__content-container {
      margin-bottom: 18px;
    }
  }
}

@media (min-width: $sm) {
  .event {
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    grid-gap: 20px;
    margin: 0;
    border-bottom: 1px solid $black;

    &__link a {
      font-size: 14.5px;
    }
    &__type {
      margin: 0 0 12px;
    }

    &:first-child {
      margin: 0;
    }

    &__info {
      padding-top: 15px;
      border-top: none;
    }

    &__title-container {
      padding: 0 0 0 40px;
    }

    &__subtitle-container {
      padding: 0 0 16px 40px;
    }

    &__btn-container {
      margin-left: 40px;

      .read-less,
      .read-more {
        vertical-align: unset;
      }
    }

    &__content {
      margin-bottom: 25px;
      padding: 0 40px;

      &-container {
        margin-bottom: 0;
        padding-top: 20px;
        position: sticky;
        top: 0;
      }
    }
  }
}

@media (min-width: $md) {
  .event {
    &__name {
      font-size: 16px;
    }

    &__type {
      font-size: 16px;
    }

    &__title {
      font-size: 16px;
    }

    &__link::before {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__date {
      text-transform: capitalize;
      font-size: 16px;
    }

    &__description {
      font-size: 16px;
    }

    &__sub-description {
      font-size: 16px;
    }

    &__link a {
      font-size: 16px;
    }

    &__btn-container {
      button {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: $lg) {
  .event {
    &__name {
      font-size: 18px;
    }

    &__type {
      font-size: 18px;
    }

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 18px;
    }

    &__link::before {
      font-size: 20px;
    }

    &__date {
      text-transform: capitalize;
      font-size: 18px;
    }

    &__description {
      font-size: 18px;
    }

    &__sub-description {
      font-size: 18px;
    }

    &__link a {
      font-size: 18px;
    }

    &__btn-container {
      button {
        font-size: 18px;
      }
    }
  }
}

@media (min-width: $xl) {
  .event {
    &__name {
      font-size: 20px;
    }

    &__type {
      font-size: 20px;
    }

    &__title {
      font-size: 20px;
    }

    &__subtitle {
      font-size: 20px;
    }

    &__date {
      text-transform: capitalize;
      font-size: 20px;
    }

    &__description {
      font-size: 20px;
    }

    &__sub-description {
      font-size: 20px;
    }

    &__link {
      a {
        font-size: 20px;
      }

      &::before {
        font-size: 22px;
      }
    }

    &__btn-container {
      button {
        font-size: 20px;
      }
    }
  }
}
