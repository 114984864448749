@import '../../styles/variables';

.menu-event {
  margin-top: 18px;

  &__name,
  &__title,
  &__untitled,
  &__type,
  &__date {
    margin: 0;
  }

  &__name {
    font-weight: bold;
    cursor: pointer;
  }

  &__title {
    font-style: italic;
    font-weight: normal;
    cursor: pointer;
  }

  &__title-bold {
    font-weight: bold;
  }

  &__untitled {
    font-weight: normal;
  }

  &__type {
    font-weight: normal;
  }

  &__date {
    text-transform: capitalize;
    font-weight: normal;
  }
}

@media (min-width: $xs) {
  .menu-event {
    margin-left: 20px;

    &__name,
    &__title,
    &__untitled,
    &__type,
    &__date {
      font-size: 14.5px;
    }
  }
}

@media (min-width: $sm) {
  .menu-event {
    margin-left: 40px;
  }
}

@media (min-width: $md) {
  .menu-event {
    &__name,
    &__title,
    &__untitled,
    &__type,
    &__date {
      font-size: 16px;
    }
  }
}

@media (min-width: $lg) {
  .menu-event {
    &__name,
    &__title,
    &__untitled,
    &__type,
    &__date {
      font-size: 18px;
    }
  }
}

@media (min-width: $xl) {
  .menu-event {
    &__name,
    &__title,
    &__untitled,
    &__type,
    &__date {
      font-size: 20px;
    }
  }
}
