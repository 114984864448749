@import '../../styles/variables';

.gallery {
  &__detailed-view {
    padding: 20px;
    background-color: $light-blue;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    right: 0;
    height: 100dvh;
    z-index: 9999;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    &__slider-container {
      position: relative;
    }

    &__slider {
      margin: 0;

      &__image-container {
        height: 70dvh;

        img {
          height: inherit;
          width: inherit;
          -o-object-fit: contain;
          object-fit: contain;
          -o-object-position: center;
          object-position: center;
        }
      }
    }

    &__slider-arrows {
      display: none;
    }

    &__slider-bottom-controls {
      font-size: 14.5px;
      display: flex;
      justify-content: space-between;
      margin: 20px 20px 0 20px;
      font-weight: bold;

      button {
        font-size: 14.5px;
        font-weight: bold;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;
        color: $black;
      }
    }

    &__slide-details {
      p {
        font-size: 14.5px;
        font-weight: normal;
        max-height: 3.6em;
        overflow-y: scroll;
      }

      a {
        font-size: 14.5px;
        line-height: inherit;
        font-weight: normal;
        white-space: pre-line;
        word-break: break-word;
        color: $black;
        text-decoration: none;

        &:before {
          content: '●';
          margin-right: 5px;
        }
      }
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
    padding: 0;
    width: 100%;

    &__item {
      height: auto;
      width: 100%;
      list-style-type: none;

      button {
        height: inherit;
        width: inherit;
        padding: 0;
        border: none;
        outline: none;
        background-color: transparent;
        cursor: pointer;

        img {
          height: 100%;
          width: 100%;
          -o-object-fit: contain;
          object-fit: contain;
          -o-object-position: center;
          object-position: center;
        }
      }
    }
  }
}

@media (min-width: $sm) {
  .gallery {
    &__list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      padding: 0 40px;
      margin-bottom: 50px;
      width: auto;
      gap: 20px;

      &__item {
        height: 300px;
        width: auto;
        text-align: center;

        button {
          height: inherit;
          width: inherit;
          padding: 0;
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;

          img {
            height: 100%;
            width: 100%;
            -o-object-fit: contain;
            object-fit: contain;
            -o-object-position: center;
            object-position: center;
          }
        }
      }
    }

    &__detailed-view {
      padding: 20px;

      &__slider {
        margin: 0 80px;
      }

      &__slider-arrows {
        display: block;
        font-size: 14.5px;

        .goNext,
        .goPrev {
          cursor: pointer;
          position: absolute;
          margin: 0 20px;
          top: calc(50% - 45px);
          font-size: 14.5px;

          &:before {
            display: inline-block;
            content: '▼';
            color: $black;
          }
        }

        .goNext {
          right: 0;
          &:before {
            transform: rotate(270deg);
          }
        }

        .goPrev:before {
          transform: rotate(-270deg);
        }
      }
    }
  }
}

@media (min-width: $md) {
  .gallery {
    &__list {
      grid-template-columns: repeat(3, 1fr);
      gap: 40px;
    }

    &__detailed-view {
      padding: 20px 60px 20px 40px;

      &__slider {
        &__image-container {
          height: 75dvh;
        }
      }

      &__slider-arrows {
        font-size: 16px;

        .goNext,
        .goPrev {
          margin: 0 40px;
          top: calc(50% - 45px);
        }
      }

      &__slider-bottom-controls {
        margin: 20px 40px 0 40px;

        font-size: 16px;

        button {
          font-size: 16px;
        }
      }

      &__slide-details {
        p,
        a {
          font-size: 16px;
        }
      }
    }
  }
}

@media (min-width: $lg) {
  .gallery {
    &__detailed-view {
      &__slider-arrows {
        .goNext,
        .goPrev {
          font-size: 18px;
        }
      }

      &__slider-bottom-controls {
        font-size: 18px;

        button {
          font-size: 18px;
        }
      }

      &__slide-details {
        p,
        a {
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: $xl) {
  .gallery {
    &__list {
      grid-template-columns: repeat(4, 1fr);
      gap: 50px;
    }

    &__detailed-view {
      padding: 20px 60px 20px 40px;

      &__slider-arrows {
        .goNext,
        .goPrev {
          font-size: 20px;
        }
      }

      &__slider-bottom-controls {
        font-size: 20px;

        button {
          font-size: 20px;
        }
      }

      &__slide-details {
        p,
        a {
          font-size: 20px;
        }
      }
    }
  }
}
