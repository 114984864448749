@import "../../styles/variables";

@media(min-width: $xs) {
  .header {
    display: flex;
    justify-content: space-between;

    &__logo-container {
      img {
        width: 121px;
        height: 23px;
      }
    }

    &__menu-container {
      .mobile-menu {
          height: 20px;
      }
      .mobile-menu:hover {
          cursor:pointer;
      }
      button {
        color: black;
        padding: 0;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 20px;
        font-weight: 100;
        transform: rotate(90deg);
        background-color: transparent;
      }
    }
  }
}

@media(min-width: $sm) {
  .header {
    margin-bottom: 63px;

    &__logo-container {
      height: 45px;

      img {
        position: relative;
        width: 100%;
        height: auto;
        cursor: pointer;
      }
    }

    &__menu-container {
      display: none;
    }
  }
}

@media(min-width: $md) {
  .header {
    margin-bottom: 83px;
  }
}

@media(min-width: $lg) {
  .header {
    margin-bottom: 103px;
  }
}

@media(min-width: $xl) {
  .header {
    margin-bottom: 123px;
  }
}
