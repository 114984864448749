@import "../../styles/variables";

$mobileWidth: calc(100vw - 40px);
$mobileHeight: calc((100vw - 40px) * 1.25);

$desktopVerticalWidth: calc(((100vw - 40px) * 0.8 - 20px) / 2 - 90px);
$desktopVerticalHeight: calc((((100vw - 40px) * 0.8 - 20px) / 2 - 90px) * 1.25);

$desktopHorizontalWidth: calc((((100vw - 40px) * 0.8 - 20px) / 2 - 180px) * 1.25);
$desktopHorizontalHeight: calc((((100vw - 40px) * 0.8 - 20px) / 2 - 180px));

.slider-desktop {
  .customized-slider {
    outline: none;

    .slick-prev {
      display: none !important;
    }

    .slick-next {
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;

      &:before {
        display: none;
      }
    }
  }
}

.slick-slide div {
  outline: none;
}

.customized-slider__caption {
  margin: 0;
  font-weight: normal;
  font-size: 14.5px;
  line-height: 18px;
}

.images-quantity {
  font-weight: bold;
}

@media(min-width: $xs) {
  .slider-desktop {
    display: none;
  }

  .slider-mobile {
      margin-top: 18px;
    .customized-slider {
      .customized-slider__image {
        width: $mobileWidth !important;
        height: $mobileHeight !important;
        display: inline-block;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  .slider-mobile.horizontal {
    .customized-slider .slick-list .slick-track .slick-slide > div > div {
      position: relative !important;
      padding-bottom: 80% !important;
      .customized-slider__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .images-quantity {
    margin-left: 20px;
  }
}

@media(min-width: $sm) {
  .slider-desktop {
    display: block;

    .customized-slider {
      .customized-slider__image {
        display: inline-block;
        object-fit: cover;
        object-position: 50% 50%;
      }
    }
  }

  .slider-desktop.vertical {
    .customized-slider {
      .customized-slider__image {
        width: $desktopVerticalWidth !important;
        height: $desktopVerticalHeight !important;
      }
    }
  }

  .slider-desktop.horizontal {
    .customized-slider .slick-list .slick-track .slick-slide > div > div {
      position: relative !important;
      padding-bottom: 80% !important;
      .customized-slider__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
      }
    }
  }

  .slider-mobile {
    display: none;
  }

  .images-quantity {
    margin-left: 40px;
  }
}

@media(min-width: $md) {
  .customized-slider__caption {
    font-size: 16px;
  }
}

@media(min-width: $lg) {
  .customized-slider__caption {
    font-size: 18px;
    line-height: 22px;
  }
}

@media(min-width: $xl) {
  .customized-slider__caption {
    font-size: 20px;
    line-height: 24px;
  }
}
