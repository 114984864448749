@import '../../styles/variables';

.menu {
  margin-bottom: 50px;

  &__upcoming,
  &__previous {
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $black;
  }

  & :first-child {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }

  &__period {
    margin: 0;
    font-weight: bold;
  }

  &__btn-container {
    margin-top: 18px;
    color: black;

    .read-less::before {
      color: black;
      margin-right: 5px;
      display: inline-block;
      content: '▼';
    }

    .read-more::before {
      color: black;
      margin-right: 5px;
      display: inline-block;
      content: '▼';
      transform: rotate(270deg);
    }

    button {
      border: none;
      outline: none;
      cursor: pointer;
      color: black;
      background-color: transparent;
    }
  }

  &__about {
    margin: 0 0 4px;
    font-weight: bold;
    cursor: pointer;
  }

  &__newsletter {
    h1 {
      margin: 0;
      padding-bottom: 9px;
      border-bottom: 1px solid $black;
      font-weight: bold;
      cursor: pointer;
    }

    input {
      width: 100%;
      margin-bottom: 0;
      padding-bottom: 9px;
      border: none;
      outline: none;
      border-bottom: 1px solid $black;
      background-color: transparent;

      &::placeholder {
        font-weight: bold;
        color: $gray;
      }
    }

    button {
      display: block;
      padding: 0;
      margin-bottom: 9px;
      border: none;
      cursor: pointer;
      outline: none;
      font-weight: bold;
      background-color: transparent;
    }
  }
}

.toast-container {
  width: auto;

  .Toastify__toast {
    min-height: auto;
    padding: 10px 20px;
  }
}

@media (min-width: $xs) {
  .menu {
    .menu__media-container {
      margin-top: 50px;
    }

    &__btn-container {
      button {
        margin: 0 0 0 20px;
        padding: 0;
        font-size: 14.5px;
      }
    }

    &__period {
      font-size: 14.5px;
    }

    &__about {
      font-size: 14.5px;
    }

    &__newsletter {
      h1 {
        font-size: 14.5px;
      }

      input {
        font-size: 14.5px;

        &::placeholder {
          font-size: 14.5px;
        }
      }

      button {
        font-size: 14.5px;
      }
    }
  }
}

@media (min-width: $sm) {
  .menu {
    &__container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 100%;

      .menu__media-container {
        margin-top: 0;
        padding-top: 30px;
      }
    }

    &__btn-container {
      button {
        font-family: inherit;
        margin: 0 0 0 40px;
        padding: 0;
      }
    }
  }
}

@media (min-width: $md) {
  .menu {
    &__period {
      font-size: 16px;
    }

    &__btn-container {
      button {
        font-size: 16px;
      }
    }

    &__about {
      font-size: 16px;
    }

    &__newsletter {
      h1 {
        font-size: 16px;
      }

      input {
        font-size: 16px;

        &::placeholder {
          font-size: 16px;
        }
      }

      button {
        font-size: 16px;
      }
    }
  }
}

@media (min-width: $lg) {
  .menu {
    &__period {
      font-size: 18px;
    }

    &__btn-container {
      button {
        font-size: 18px;
      }
    }

    &__about {
      font-size: 18px;
    }

    &__newsletter {
      h1 {
        font-size: 18px;
      }

      input {
        font-size: 18px;

        &::placeholder {
          font-size: 18px;
        }
      }

      button {
        font-size: 18px;
      }
    }
  }
}

@media (min-width: $xl) {
  .menu {
    &__period {
      font-size: 20px;
    }

    &__btn-container {
      button {
        font-size: 20px;
      }
    }

    &__about {
      font-size: 20px;
    }

    &__newsletter {
      h1 {
        font-size: 20px;
      }

      input {
        font-size: 20px;

        &::placeholder {
          font-size: 20px;
        }
      }

      button {
        font-size: 20px;
      }
    }
  }
}
