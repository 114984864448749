@import '../../styles/variables';

.sidebar {
  width: calc(100vw - 40px);
  margin: 0;
  z-index: 3 !important;
  background-color: $light-blue;
}

.overlay {
  z-index: 2 !important;
}

.sidebar-content {
  padding: 20px 20px 0;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo-container {
    width: 121px;
    height: 23px;

    img {
      width: inherit;
      height: inherit;
    }
  }

  &__cross-container {
    width: 23px;
    height: 23px;

    button {
      margin: 0;
      padding: 0;
      border: none;
      outline: none;
      background-color: transparent;
    }
  }
}

@media (min-width: $xs) {
  .sidebar {
    padding: 0 20px 0;

    .sidebar-header {
      display: flex;
      position: sticky;
      top: 0;
      width: calc(100vw - 40px);
      padding-top: 20px;
      box-sizing: border-box;
      transition: 0.1s;
      z-index: 1;
      background-color: $light-blue;
    }

    .menu {
      div:first-child {
        margin-top: 50px;
      }
    }
  }

  .sidebar-content {
    .header__container {
      position: fixed;
      top: 0;
      height: 28px;
      width: calc(100vw - 40px);
      padding: 20px 0 40px;
      box-sizing: border-box;
      transition: 0.1s;
      z-index: 1;
      background-color: $light-blue;
    }
  }
}

@media (min-width: $sm) {
  .sidebar {
    padding: 0 20px;

    .sidebar-header {
      padding-top: 20px;
    }
  }

  .sidebar-content {
    padding: 0 0 0 20px;

    .header__container {
      display: none;
      position: static !important;
      width: 100%;
      padding: 0;
      height: 49px;
    }

    .main__menu-container {
      .header {
        display: block;
        margin-top: 20px;
      }
    }
  }
}
