@import '../../styles/variables';

.main {
  display: grid;

  &__exhibitions-list-container {
    position: relative;
    margin-top: 50px;
  }
}

@media (min-width: $xs) {
  .main {
    &__menu-container {
      display: none;
    }

    &__exhibitions-list-container {
      width: calc(100vw - 40px);
    }
  }
}

@media (min-width: $sm) {
  .main {
    grid-template-columns: 20% auto;
    grid-gap: 20px;
    height: 100vh;
    overflow: hidden;

    &__menu-container {
      display: block;
      float: left;
      height: 100vh;
      overflow-y: hidden;

      .menu {
        height: calc(100vh - 128px);
        overflow-x: hidden;
        overflow-y: auto;

        .menu__newsletter {
          margin-bottom: 20px;
        }
      }
    }

    &__content-container {
      padding-right: 20px;
      overflow-y: auto;
    }

    &__exhibitions-list-container {
      display: block;
      float: right;
      height: 100vh;
      width: auto;

      margin-top: 35px;
    }
  }
}

@media (min-width: $md) {
  .main {
    &__menu-container {
      .menu {
        height: calc(100vh - 148px);
      }
    }

    &__exhibitions-list-container {
      margin-top: 45px;
    }
  }
}

@media (min-width: $lg) {
  .main {
    &__menu-container {
      .menu {
        height: calc(100vh - 168px);
      }
    }

    &__exhibitions-list-container {
      margin-top: 55px;
    }
  }
}

@media (min-width: $xl) {
  .main {
    &__menu-container {
      .menu {
        height: calc(100vh - 188px);
      }
    }

    &__exhibitions-list-container {
      margin-top: 70px;
    }
  }
}
