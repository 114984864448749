@import '../../../styles/variables';

.expandable-content {
  &__toggle {
    color: black;
    padding: 0;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: transparent;
    font-family: inherit;
  }

  .collapse-toggle::before {
    margin-right: 5px;
    color: black;
    display: inline-block;
    content: '▼';
  }

  .expand-toggle::before {
    margin-right: 5px;
    color: black;
    display: inline-block;
    content: '▼';
    transform: rotate(270deg);
  }
}

@media (min-width: $xs) {
  .expandable-content {
    &__toggle {
      margin-left: 20px;
      margin-bottom: 18px;
      font-size: 14.5px;
    }
  }
}

@media (min-width: $sm) {
  .expandable-content {
    &__toggle {
      margin-left: 40px;
      margin-bottom: 18px;
    }
  }
}

@media (min-width: $md) {
  .expandable-content {
    &__toggle {
      font-size: 16px;
    }
  }
}

@media (min-width: $lg) {
  .expandable-content {
    &__toggle {
      font-size: 18px;
    }
  }
}

@media (min-width: $xl) {
  .expandable-content {
    &__toggle {
      font-size: 20px;
    }
  }
}
