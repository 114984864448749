@import '../../styles/variables';

.education-list {
  &__title {
    margin: 0;
    font-weight: bold;
    border-top: 1px solid $black;
    padding: 20px 0;
  }
}

@media (min-width: $xs) {
  .education-list {
    &__title {
      font-size: 14.5px;
    }
  }
}

@media (min-width: $sm) {
  .education-list {
    &__title {
      padding: 15px 0;
      border-top: none;
      border-bottom: 1px solid $black;
    }
  }
}

@media (min-width: $md) {
  .education-list {
    &__title {
      font-size: 16px;
    }
  }
}

@media (min-width: $lg) {
  .education-list {
    &__title {
      font-size: 18px;
    }
  }
}

@media (min-width: $xl) {
  .education-list {
    &__title {
      font-size: 20px;
    }
  }
}
