@import "./styles/variables";

@font-face {
  font-family: 'Unica77LL';
  font-style: normal;
  font-weight: normal;
  src: url('./assets/fonts/Unica77LL-Regular.otf');
}

@font-face {
  font-family: 'Unica77LL';
  font-style: italic;
  font-weight: normal;
  src: url('./assets/fonts/Unica77LL-Italic.otf');
}

@font-face {
  font-family: 'Unica77LL';
  font-style: normal;
  font-weight: bold;
  src: url('./assets/fonts/Unica77LL-Bold.otf');
}

@font-face {
  font-family: 'Unica77LL';
  font-style: italic;
  font-weight: bold;
  src: url('./assets/fonts/Unica77LL-BoldItalic.otf');
}

body {
  margin: 0;
  font-family: "Unica77LL", sans-serif;
  background-color: $light-blue;
}

.error-message {
  padding-top: 100px;

  h1 {
    margin: 0;
    text-align: center;
    font-size: 14.5px;
    line-height: 18px;
    font-weight: bold;
  }

  h2 {
    margin: 10px 0 0;
    text-align: center;
    font-size: 14.5px;
    line-height: 18px;
    font-weight: normal;
  }
}
