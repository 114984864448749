@import '../../styles/variables';

.exhibition {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  padding: 25px 0;
  border-bottom: 1px solid $black;

  &:last-child {
    border-bottom: none;
  }

  &__slider {
    padding: 0;
  }

  &__name {
    margin: 0;
    font-weight: bold;
  }

  &__title {
    margin: 0;
    font-weight: bold;
    font-style: italic;
  }

  &__subtitle {
    margin: 0;
    font-weight: normal;
  }

  &__date {
    text-transform: capitalize;
    font-weight: normal;
  }

  &__description {
    margin: 18px 0 18px 0px;
    font-weight: normal;
    white-space: pre-line;
  }

  &__btn-container {
    button {
      color: black;
      padding: 0;
      border: none;
      outline: none;
      cursor: pointer;
      background-color: transparent;
    }

    .read-less::before {
      margin-right: 5px;
      color: black;
      display: inline-block;
      content: '▼';
    }

    .read-more::before {
      margin-right: 5px;
      color: black;
      display: inline-block;
      content: '▼';
      transform: rotate(270deg);
    }
  }

  &__sub-description {
    margin: 0 0 20px;
    font-weight: normal;
    white-space: pre-line;

    &:last-child {
      margin: 0;
    }
  }

  &__list-of-people {
    margin: 0;
    padding: 0;
    font-weight: normal;
    white-space: pre-line;
  }

  &__title-container {
    padding: 20px 0 0 20px;
    border-top: 1px solid $black;
  }

  &__subHeader {
    /*font-weight: bold;*/
    padding: 0px 0px 0px 40px;
  }

  .exhibition__title-container {
    border-top: none;
  }

  &__pdf-files-container {
    .exhibition__pdf-file {
      margin: 0;
      color: black;
      padding: 0;
      font-weight: normal;
      white-space: pre-line;
      font-size: 14.5px;

      a {
        text-decoration: none;
        color: $black;

        &::before {
          content: '●';
          margin-right: 5px;
          color: $black;
        }
      }
    }
  }
}

@media (min-width: $xs) {
  .exhibition-without-gallery,
  .exhibition-with-gallery,
  .exhibition {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'slider'
      'content';

    .exhibition__header {
      grid-area: header;
    }

    .exhibition__slider {
      grid-area: slider;
    }

    .exhibition__content {
      grid-area: content;
    }

    &__name,
    &__title,
    &__subtitle,
    &__date,
    &__description,
    &__sub-description {
      font-size: 14.5px;
    }

    &__subHeader {
      font-size: 14.5px;
      padding-left: 20px;
    }

    &__subtitle-container {
      padding: 0 0 10px 20px;
    }

    &__btn-container {
      margin-left: 20px;

      button {
        margin-bottom: 18px;
        font-size: 14.5px;
      }
    }

    &__list-of-people {
      margin-left: 20px;
      font-size: 14.5px;
    }

    &__content {
      margin-bottom: 0;
      padding: 0;
    }

    &__pdf-files-container {
      margin: 0 0 0 20px;

      .exhibition__pdf-file {
        color: black;
        font-size: 14.5px;

        a::before {
          color: black;
          font-size: 16px;
        }
      }
    }

    &:first-child {
      .exhibition__title-container {
        border-top: none;
      }
    }
  }
}

@media (min-width: $sm) {
  .exhibition-without-gallery {
    grid-gap: 0;

    .exhibition__header {
      grid-column: 1;
      grid-row: 1;
    }

    .exhibition__content {
      grid-column: 1;
      grid-row: 2;
    }

    .exhibition__slider {
      grid-column: 2;
      grid-row: 1 / 3;
    }
  }

  .exhibition-with-gallery {
    .exhibition__header {
      grid-column: 1 / -1;
      grid-row: 1;
    }

    .exhibition__content {
      grid-column: 1;
      grid-row: 2;
    }

    .exhibition__slider {
      grid-column: 2;
      grid-row: 2;
    }
  }

  .exhibition {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: calc(50% - 10px) calc(50% - 10px);
    margin: 0;

    &:last-child {
      border-bottom: 1px solid $black;
    }

    &__slider {
      padding: 0 40px;
    }

    &__info {
      padding-top: 15px;
    }

    &__title-container {
      padding: 0 0 0 40px;
      border-top: none;
    }

    &__subHeader {
      padding: 0px 0px 0px 40px;
    }

    &__subtitle-container {
      padding: 0 0 10px 40px;
    }

    &__btn-container {
      margin-left: 40px;

      button {
        margin-bottom: 18px;
      }
    }

    &__list-of-people {
      margin: 0 0 20px 40px;
    }

    &__content {
      &-container {
        margin-bottom: 0;
        position: sticky;
        top: 20px;
      }
    }

    &__pdf-files-container {
      margin: 0 0 0px 40px;
    }

    &__sub-description {
      margin: 0 0 20px;

      &:last-child {
        margin: 0 0 20px;
      }
    }
  }
}

@media (min-width: $md) {
  .exhibition {
    &__name {
      font-size: 16px;
    }

    &__title {
      font-size: 16px;
    }

    &__subtitle {
      font-size: 16px;
    }

    &__date {
      font-size: 16px;
    }

    &__description {
      font-size: 16px;
    }

    &__subHeader {
      font-size: 16px;
    }

    &__btn-container {
      button {
        font-size: 16px;
      }
    }

    &__sub-description {
      font-size: 16px;
    }

    &__list-of-people {
      font-size: 16px;
    }

    &__pdf-files-container {
      .exhibition__pdf-file {
        font-size: 16px;

        a::before {
          font-size: 18px;
        }
      }
    }
  }
}

@media (min-width: $lg) {
  .exhibition {
    &__name {
      font-size: 18px;
    }

    &__title {
      font-size: 18px;
    }

    &__subtitle {
      font-size: 18px;
    }

    &__date {
      font-size: 18px;
    }

    &__description {
      font-size: 18px;
    }

    &__subHeader {
      font-size: 18px;
    }

    &__btn-container {
      button {
        font-size: 18px;
      }
    }

    &__sub-description {
      font-size: 18px;
    }

    &__list-of-people {
      font-size: 18px;
    }

    &__pdf-files-container {
      .exhibition__pdf-file {
        font-size: 18px;

        a::before {
          font-size: 20px;
        }
      }
    }
  }
}

@media (min-width: $xl) {
  .exhibition {
    &__name {
      font-size: 20px;
    }

    &__title {
      font-size: 20px;
    }

    &__subtitle {
      font-size: 20px;
    }

    &__date {
      font-size: 20px;
    }

    &__description {
      font-size: 20px;
    }

    &__subHeader {
      font-size: 20px;
    }

    &__btn-container {
      button {
        font-size: 20px;
      }
    }

    &__sub-description {
      font-size: 20px;
    }

    &__list-of-people {
      font-size: 20px;
    }

    &__pdf-files-container {
      .exhibition__pdf-file {
        font-size: 20px;

        a::before {
          font-size: 22px;
        }
      }
    }
  }
}
