@import "../../styles/variables";

.about {
  display: grid;

  &__description-item {
    margin-bottom: 30px;
  }

  &__title {
    margin: 0 0 18px;
    font-weight: bold;
  }

  &__text {
    margin: 0 0 18px;
    font-weight: normal;
    white-space: pre-line;
  }

  &__contacts, &__visit {
    margin-bottom: 30px;
    white-space: pre-line;
  }

  &__visit {
    a {
      text-decoration: none;
      color: $black;
    }
  }
}

@media(min-width: $xs) {
  .about {
    grid-template-columns: 100%;
    margin-top: 0px;
    padding-top: 20px;
    border-top: 1px solid $black;

    &__title {
      margin-left: 20px;
      font-size: 14.5px;
    }

    &__text {
      font-size: 14.5px;
    }
  }
}

@media(min-width: $sm) {
  .about {
    grid-template-columns: 50% 1fr 1fr;
    grid-gap: 16px;
    margin-top: 0;
    padding-top: 20px;
    border-top: none;
    padding-bottom: 50px;

    &__title {
      margin-left: 40px;
    }
  }
}

@media(min-width: $md) {
  .about {
    &__title {
      font-size: 16px;
    }

    &__text {
      font-size: 16px;
    }
  }
}

@media(min-width: $lg) {
  .about {
    &__title {
      font-size: 18px;
    }

    &__text {
      font-size: 18px;
    }
  }
}

@media(min-width: $xl) {
  .about {
    &__title {
      font-size: 20px;
    }

    &__text {
      font-size: 20px;
    }
  }
}
