@import '../../styles/variables';

.bold {
  font-weight: bold;
}

.language-switcher {
  cursor: pointer;
  margin-top: 18px;
  text-align: right;
  font-size: 14.5px;

  @media (min-width: $md) {
    font-size: 16px;
  }

  @media (min-width: $lg) {
    font-size: 18px;
  }

  @media (min-width: $xl) {
    font-size: 20px;
  }
}

@media (max-width: $sm) {
  #hideOnMobile {
    display: none;
  }
  .language-switcher {
    margin-top: 0px;
    margin-left: auto;
    margin-right: 20px;
  }
}
